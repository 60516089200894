import { shallowEqual, useSelector } from "react-redux";
import { ApplicationState } from "../reducers";
import { GLOBAL_CONFIG } from "../../components/organisms/Sidebar/constants";

export const useConfig = ({ forceGlobal = false } = {}) => {
  const { brands } = useSelector(
    (state: ApplicationState) => state.auth,
    shallowEqual
  );

  const brand = useSelector(
    (state: ApplicationState) => state.activeBrand,
    shallowEqual
  );

  const brandData = brands.find((b) => b.id === brand.id);

  const brandName = brand.name;
  const brandId = forceGlobal ? null : brand.id;
  const brandColor = brand.color;
  const brandIcon = brand.icon;

  const config = !!brandId && !forceGlobal ? "brand" : "system";
  const isGlobalConfig = brandName === GLOBAL_CONFIG;

  return {
    brandData,
    brandName,
    brandId,
    brandColor,
    brandIcon,
    config,
    isGlobalConfig,
  };
};
