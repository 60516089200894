import React, {
  useEffect,
  useRef,
  useState,
} from "react";
import { Layout, Menu, Spin } from "antd";
import { sidebarConfig, sidebarData } from "./constants";
import { APP_ROUTES } from "../../../system/router/constants";
import { renderMenuItem } from "../../atoms/MenuItem";
import { useLocation } from "react-router-dom";
import BrandSelector from "./BrandSelector";
import { shallowEqual, useSelector } from "react-redux";
import { ApplicationState } from "../../../system/reducers";
import { useConfig } from "../../../system/hooks/useConfig";
import { usePermissionAccess } from "../../../system/hooks/usePermissionAccess";
import { SidebarProps } from "./types";
import { useSSOWidget } from "../../../system/integrations/sso";

const { Sider } = Layout;

const Sidebar: React.FC<SidebarProps> = ({ className, logo }) => {
  const location = useLocation();
  const { checkPermission, isSuperAdmin } = usePermissionAccess();
  const currentPath = location.pathname;
  const userData = useSelector(
    (state: ApplicationState) => state.auth,
    shallowEqual
  );

  const containerRef = useRef(null);
  const { isGlobalConfig } = useConfig();
  const [data, setData] = useState([]);

  useEffect(() => {
    let sidebarOnGlobConf = [
      ...sidebarData(isGlobalConfig).filter((item) => {
        if (!item.hidden && (isSuperAdmin || checkPermission(item.access))) {
          if (!!item.subData) {
            item.subData = item.subData.filter((subEl) => {
              return (
                !subEl.hidden && (isSuperAdmin || checkPermission(subEl.access))
              );
            });
          }
          return true;
        }
        return false;
      }),
    ];
    
    setData(sidebarOnGlobConf);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGlobalConfig, isSuperAdmin, userData, setData]);

  useSSOWidget({ rootSelector: "#sso-menu" });

  return (
    <Sider {...sidebarConfig} className={className} trigger={null}>
      <div ref={containerRef}>
        <div className="logo-wrapper">
          <div id="sso-menu" />
          {!!logo && logo}
        </div>
        <Menu
          className="menu-container"
          theme="light"
          mode="inline"
          selectedKeys={[currentPath]}
          defaultSelectedKeys={[APP_ROUTES.dashboard]}
          items={[
            {
              key: "brand-selector",
              className: "dropdown__nav-menu",
              label: userData.isLoading ? (
                <div key="loader" className="loader-wrapper">
                  <Spin />
                </div>
              ) : (
                <BrandSelector
                  brandData={userData.brands || []}
                  sidebarRef={containerRef}
                />
              ),
            },
            ...data.map(
              ({ label, route, icon, activeIcon, subData, access }, key) =>
                subData
                  ? {
                      key: key,
                      icon: icon,
                      label: renderMenuItem({
                        label,
                        access,
                      }),
                      className:
                        subData.some(({ route }) => route === currentPath) &&
                        "ant-menu-submenu-selected",
                      children: subData.map(
                        ({ label, route, access }, subKey) => ({
                          key: `${key}-${subKey}`,
                          className:
                            currentPath === route && "ant-menu-item-selected",
                          label: renderMenuItem({
                            route,
                            label,
                            access,
                          }),
                        })
                      ),
                    }
                  : {
                      key: key,
                      icon: currentPath === route ? activeIcon : icon,
                      className:
                        currentPath === route && "ant-menu-item-selected",
                      label: renderMenuItem({
                        route,
                        label,
                        access,
                      }),
                    }
            ),
          ]}
        />
      </div>
    </Sider>
  );
};

export default Sidebar;
