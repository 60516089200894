import React, { useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ApplicationState } from "../../system/reducers";
import { Spin } from "antd";
import AntBtn from "../../components/atoms/AntBtn";
import classNames from "classnames";
import updateActiveBrand from "../../modules/ActiveBrand/api/action";
import { ReactComponent as GearIcon } from "../../images/icons/elements/gear.svg";
import BrandIcon from "../../components/atoms/BrandIcon";
import { getBrandCode } from "../../system/helpers/helpfulFunctions";
import { globalConfigState } from "../../modules/ActiveBrand/constants";
import { useConfig } from "../../system/hooks/useConfig";
import TooltipParagraph from "../../components/molecules/TooltipParagraph";
import { usePermissionAccess } from "../../system/hooks/usePermissionAccess";
import { APP_ROUTES } from "../../system/router/constants";
import { ReactComponent as AddRoleIcon } from "../../images/icons/elements/plus-bordered.svg";

const DashboardPage = () => {
  const { isLoading, name, brands } = useSelector(
    (state: ApplicationState) => state.auth,
    shallowEqual
  );

  const navigate = useNavigate();
  const { brandName, isGlobalConfig } = useConfig();

  const brandList: {
    name: string;
    color: string;
    id: string | number;
  }[] = useMemo(
    () =>
      brands?.map((brand) => ({
        name: brand["name"],
        color: brand.settings.color || "#FFA53A",
        id: brand.id,
      })),
    [brands]
  );

  const dispatch = useDispatch();

  const selectBrand = (brand) => dispatch(updateActiveBrand(brand));

  const { isSuperAdmin, hasGlobalConfigAccess } = usePermissionAccess();
  const moreThenOneBrand = brandList?.length > 1;

  return (
    <div className="dashboard-wrapper">
      <div className="dashboard-container">
        {isLoading ? (
          <Spin />
        ) : (
          <>
            <span className="greeting-message">
              Hi {name}, <br />
              welcome to HUB
            </span>
            <br />
            {moreThenOneBrand && (
              <span className="cta-message">
                Select the brand you want to start working on.
              </span>
            )}
            <div className="brands-list-section">
              {(hasGlobalConfigAccess || isSuperAdmin) && (
                <>
                  <AntBtn
                    onClick={() => navigate(APP_ROUTES.brandsCreate)}
                    className="create-new-brand-ticket"
                    text={
                      <>
                        <AddRoleIcon className="add-brand-icon" />
                        <span className="create-new-brand-ticket__text">
                          Create a new brand
                        </span>
                      </>
                    }
                  />
                  <div
                    onClick={() => selectBrand(globalConfigState)}
                    className={classNames("global-configuration-ticket", {
                      "global-configuration-ticket__selected": isGlobalConfig,
                    })}
                  >
                    <span className="global-configuration__icon">
                      <GearIcon />
                    </span>
                    <span className="global-configuration__label">
                      Global Configuration
                    </span>
                  </div>
                </>
              )}
              {(moreThenOneBrand || hasGlobalConfigAccess || isSuperAdmin) &&
                brandList?.map(({ name, color, id }, key) => (
                  <div
                    key={key}
                    onClick={() =>
                      selectBrand({
                        name: name,
                        icon: getBrandCode(name),
                        color: color,
                        id: id,
                      })
                    }
                    className={classNames("brand-ticket", {
                      "brand-ticket__selected": brandName === name,
                    })}
                    style={
                      brandName !== name
                        ? {
                            background:
                              "linear-gradient(rgba(0, 0, 0, 0.2), rgba(255, 255, 255, 0.2))",
                            backgroundColor: `${color}`,
                          }
                        : { background: "transparent" }
                    }
                  >
                    <BrandIcon
                      opacity={brandName === name && "0.4"}
                      brandName={name}
                      backgroundColor={
                        brandName !== name
                          ? "rgba(255, 255, 255, 0.4)"
                          : `${color}`
                      }
                    />
                    <span className="brand-name">
                      <TooltipParagraph ellipsis>{name}</TooltipParagraph>
                    </span>
                  </div>
                ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DashboardPage;
