import React, { useEffect, useMemo } from "react";
import { Button, Dropdown } from "antd";
import { renderMenuItem } from "../../atoms/MenuItem";
import { brandDataType } from "../../../modules/Brands/constants";
import PlusIcon from "../../../images/icons/elements/plus.svg";
import classNames from "classnames";
import {
  getBrandCode,
  insertIf,
} from "../../../system/helpers/helpfulFunctions";
import { globalConfigState } from "../../../modules/ActiveBrand/constants";
import { useDispatch } from "react-redux";
import updateActiveBrand from "../../../modules/ActiveBrand/api/action";
import { useConfig } from "../../../system/hooks/useConfig";
import TooltipParagraph from "../../molecules/TooltipParagraph";
import { usePermissionAccess } from "../../../system/hooks/usePermissionAccess";
import { useQueryClient } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../../system/router/constants";

const DEFAULT_BRAND_COLOR = "#FFA53A";

interface BrandSelectorProps {
  brandData: brandDataType[];
  sidebarRef: any;
}

const BrandSelector: React.FC<BrandSelectorProps> = ({
  brandData,
  sidebarRef,
}) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isGlobalConfig, brandName, brandColor, brandIcon } = useConfig();
  const { isSuperAdmin, hasGlobalConfigAccess } = usePermissionAccess();
  const cachedBrand = JSON.parse(localStorage.getItem("brand"));

  const brandList = useMemo(
    () =>
      [
        ...insertIf(isSuperAdmin || hasGlobalConfigAccess, globalConfigState),
        ...brandData.map(({ name, id, settings: { color } }) => ({
          name: name,
          id: id,
          icon: getBrandCode(name),
          color: color || DEFAULT_BRAND_COLOR,
        })),
      ].filter(({ name }) => name !== brandName),
    [brandData, brandName, isSuperAdmin, hasGlobalConfigAccess]
  );

  useEffect(() => {
    if (!!cachedBrand && pathname !== APP_ROUTES.brandsCreate) {
      dispatch(
        updateActiveBrand({
          id: cachedBrand.id,
          name: cachedBrand.name,
          icon: cachedBrand.icon,
          color: cachedBrand.color,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, cachedBrand]);

  const queryClient = useQueryClient();

  const onConfigSelect = (config) => {
    dispatch(updateActiveBrand(config));
    queryClient.invalidateQueries({ refetchType: "none" });
  };

  return (
    <Dropdown
      key={1}
      disabled={pathname === APP_ROUTES.brandsCreate}
      trigger={["click"]}
      menu={{
        items: [
          ...brandList.map(({ name, id, icon, color }, index) => ({
            key: index,
            label: (
              <>
                <span className="brand-title">
                  <span
                    className="brand-icon"
                    style={{
                      backgroundColor: color,
                    }}
                  >
                    {icon}
                  </span>
                  {name}
                </span>
              </>
            ),
            onClick: () => onConfigSelect({ name, color, id, icon }),
          })),
          isGlobalConfig && {
            key: "new-brand",
            label: renderMenuItem({
              label: "Create a new brand",
              icon: PlusIcon,
            }),
            onClick: () => navigate(APP_ROUTES.brandsCreate),
          },
        ],
      }}
      overlayClassName={classNames("brand-select-dropdown__overlay", {
        "global-config": isGlobalConfig,
        "several-brand-selected":
          (isSuperAdmin || hasGlobalConfigAccess) &&
          !isGlobalConfig &&
          brandList.length > 1,
      })}
      getPopupContainer={() => sidebarRef.current}
    >
      <Button
        className={classNames("brand-select-dropdown__btn", {
          "single-config": !brandList.length,
        })}
      >
        <span className="brand-select-dropdown__brand-name">
          <span
            className="selected-brand-icon"
            style={{
              backgroundColor: !!brandColor ? brandColor : DEFAULT_BRAND_COLOR,
            }}
          >
            {brandIcon}
          </span>
          <span className="brand-name-label">
            <TooltipParagraph>{brandName}</TooltipParagraph>
          </span>
        </span>
        <span className="brand-select-dropdown__arrow-down" />
      </Button>
    </Dropdown>
  );
};

export default BrandSelector;
