import { message } from "antd";
import { useDispatch } from "react-redux";
import { useOAuthAxiosClient } from "../api/axiosConfig";
import { useCallback } from "react";
import { AxiosInstance, AxiosRequestConfig } from "axios";
import config from "../config";
import { resetStorage, verifySSOToken } from "../../modules/Auth/api/helpers";
import setSSOToken from "../../modules/Auth/api/actions";

export type RequestParams = {
  type?: string;
  request: AxiosRequestConfig;
};

export const handleSSOErrorByStatus = (status: 403 | 401) => {
  if (status === 401) {
    resetStorage();
    setSSOToken(null);
    verifySSOToken(window.location);
    return;
  }

  if (status === 403) {
    window.location.assign(config.SSO_DOMAIN_URL);
    resetStorage();
    return;
  }
};

export const useSSOApiRequest = (_client: AxiosInstance = null) => {
  const authClient = useOAuthAxiosClient();
  const client = _client ? _client : authClient;

  const apiRequest = useCallback(
    async <T = any>(params: RequestParams) =>
      client.request<T>(params?.request).then((response) => response?.data),
    [client]
  );

  const apiErrors = (err, notify = true) => {
    const errors = err.error?.data?.errors;
    const errMessage = err.error?.data?.message;
    const errStatus = [err?.error?.status, err.response?.status];

    if (errStatus.includes(401)) {
      handleSSOErrorByStatus(401);
    }

    if (errStatus.includes(403)) {
      handleSSOErrorByStatus(403);
    }

    if (notify) {
      if (!!errors) {
        Object.keys(errors).forEach((error) => message.error(errors[error][0]));
      } else if (!!errMessage) {
        message.error(errMessage);
      } else {
        message.error("Some error occurred");
      }
    }

    return errors;
  };

  return { apiRequest, apiErrors };
};

export const useApiRequest = () => {
  const dispatch: any = useDispatch();

  const apiRequest = (params, withHeaders = false) =>
    dispatch(params)
      .then((data: any) => {
        if (!!params.meta) {
          return {
            data: data.payload,
            meta: params.meta,
          };
        }

        if (!withHeaders) {
          return data.payload;
        }

        return {
          data: data.payload,
          headers: data.resHeaders,
        };
      })
      .catch((err) => {
        if (err.error?.status === 401) {
          handleSSOErrorByStatus(401);
        }
        throw err
      });

  const apiErrors = (err, notify = true) => {
    const errors = err.error?.data?.errors;
    const errMessage = err.error?.data?.message;

    if (err.error && err.error.status === 401) {
      handleSSOErrorByStatus(401);
      return;
    }

    if (notify) {
      if (!!errors) {
        Object.keys(errors).forEach((error) => message.error(errors[error][0]));
      } else if (!!errMessage) {
        message.error(errMessage);
      } else {
        message.error("Some error occurred");
      }
    }

    return errors;
  };

  return { apiRequest, apiErrors };
};
