import { Navigate } from "react-router-dom";
import { useConfig } from "../hooks/useConfig";
import { APP_ROUTES } from "./constants";
import { RoleWrapper } from "../roles/RoleWrapper";
import { shallowEqual, useSelector } from "react-redux";
import { isLoggedIn } from "../helpers/helpfulFunctions";
import { ApplicationState } from "../reducers";
import FullScreenLoader from "../../components/molecules/FullScreenLoader";
import { useInitializeToken } from "../../modules/Auth/api/hooks";

const PrivateRoute = ({
  children,
  permissions = {
    group: [],
    alias: [],
    action: [],
  },
  brandRoute = false,
}) => {
  const { isGlobalConfig } = useConfig();
  const { token, sso_token } = useSelector(
    (state: ApplicationState) => state.auth,
    shallowEqual
  );
  const cachedBrand = JSON.parse(localStorage.getItem("brand"));

  useInitializeToken();

  const unauthorized = !sso_token && !token && !isLoggedIn();
  if (unauthorized) {
    return <FullScreenLoader />;
  }

  if (!cachedBrand && isGlobalConfig && brandRoute) {
    return <Navigate to={APP_ROUTES.dashboard} />;
  }

  return (
    <RoleWrapper hasAccess={permissions} redirect>
      {children}
    </RoleWrapper>
  );
};

export default PrivateRoute;
